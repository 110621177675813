.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.text-prim {
    color: #0a1d4f !important;
}

.border-prim,
.highLightHover:hover,
.planCards>div:hover {
    border-color: #0a1d4f !important;
}

.planCards>div {
    box-shadow: 0px 4px 40px rgba(196, 200, 210, 0.4);
    border: 0.3 solid;
    border-radius: 10px;
}

.border-prim2 {
    border-color: #183171 !important;
}

.text-prim2,
a {
    color: #183171 !important;
}

.bg-prim2 {
    background-color: #183171 !important;
}

.bg-prim {
    background-color: #0a1d4f !important;
}

.bg-lightGreen {
    background-color: #f0fff6 !important;
}

.text-greenTag {
    color: #3aae6b !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

tr>th:first-child {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

table * {
    vertical-align: middle !important;
}

.pagination ul {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    list-style: none;
}

.pagination li {
    padding-right: 1rem;
}