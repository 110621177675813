body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

p {
    font-weight: 300;
}

#root {
    height: 100vh;
    width: 100vw;
}

#root>*:first-child {
    height: 100%;
    overflow: hidden;
}

.mapView>div>div {
    border: none;
}

/* .auth > aside > *:first-child {
    max-width: 500px !important;
    margin: auto;
} */

a {
    text-decoration: none;
}

aside {
    width: 200px !important;
}

main {
    width: calc(100vw - 200px);
}

.auth aside {
    width: 500px !important;
}

.auth aside>.mainBody {
    margin-left: 70px;
    margin-right: 70px;
}

.auth main {
    width: calc(100% - 500px) !important;
}

@media (max-width: 768px) {
    .auth main {
        width: 100% !important;
    }

    main {
        width: 100% !important;
    }
}

login input {
    border: 0.4px solid #c3d3ff;
}

.optionText {
    width: 30px;
    height: 30px;
}

.mainBody {
    margin-top: 230px;
    height: calc(100% - 230px);
}

.mainBody.comp {
    margin-top: 100px;
    height: calc(100% - 100px);
}

.menuHeight {
    max-height: 300px;
    overflow: scroll;
}

.noFocusWithin:focus-visible,
.noFocusWithin:focus-within,
.mapView>div>div:focus-visible,
.mapView>div>div:focus-within {
    outline: none !important;
}

.dashboardBanner {
    background-image: url("./assets/svg/dashboardBanner.svg");
    background-position-x: right;
    background-repeat: no-repeat;
    border-radius: 10px;
}

.dashCard {
    border-radius: 20px;
}

.dashCard.last {
    border-style: dashed !important;
}

.rounded.round {
    border-radius: 10px !important;
}

.waves {
    position: absolute;
    bottom: 0;
    width: 500px;
    left: -300px;
    z-index: 0;
}

.routeBorder {
    background-color: #f4f7ff;
    border-right: 5px solid #183171;
}

.longHeight {
    line-height: 35px;
}

.customShadow {
    box-shadow: 0px 4px 40px rgba(196, 200, 210, 0.4);
}

.hoverClass:hover {
    background-color: #f0ebf7bf;
}

.table> :not(caption)>*>* {
    padding: 1.5rem;
}

.calendaar>div {
    border: 0;
    padding: 6px;
}

.assessmentDownload {
    background-image: url("./assets/svg/inactiveDownload.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.assessmentDownload:hover[data-active="true"] {
    cursor: pointer;
    background-color: #183171;
    background-image: url("./assets/svg/activeDownload.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.lock {
    background-image: url("./assets/svg/dashLock.svg");
    background-repeat: no-repeat;
    background-position: center center;
    cursor: disabled;
}

.dashEllipses {
    background-image: url("./assets/svg/ellipses.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

.assessmentResults:hover {
    background-color: #f6f8fa;
    cursor: pointer;
}


.leftBorder {
    border-left-width: 10px !important;
}

.checkedIconClass {
    list-style-image: url("./assets/svg/tick.svg");
}

.balanceCount {
    top: -7px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    right: -5px;
    font-size: 10px;
}