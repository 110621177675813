.carousel-body {
    width: 100%;
    height: 100%;
}

@keyframes display {
    0% {
        transform: translateX(200px);
        opacity: 0;
    }
    20% {
        transform: translateX(0);
        opacity: 1;
    }
    30% {
        transform: translateX(0);
        opacity: 1;
    }
    60% {
        transform: translateX(-200px);
        opacity: 0;
    }
    100% {
        transform: translateX(-200px);
        opacity: 0;
    }
}

.carousel-body {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 0;
    overflow: hidden;
}

.carousel-body > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    animation: display 9s infinite;
    justify-content: center;
}

.carousel-body > *:nth-child(2) {
    animation-delay: 3s;
}

.carousel-body > *:nth-child(3) {
    animation-delay: 6s;
}

.btmRing {
    position: absolute;
    bottom: 0;
    left: 0;
}
